import React, { useEffect } from 'react'
import { useState } from 'react'
import { useEulaService } from './useEulaService'
import { Logo } from './Logo'
import NotAuthorized from './NotAuthorized'
import { BottomScrollListener } from 'react-bottom-scroll-listener'

const containerStyle = {
  display: 'flex',
  flexDirection: 'column' as any,
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '5rem',
  marginLeft: '5rem',
  backgroundColor: 'white',
  maxWidth: '100%',
  padding: '1rem',
}
const buttonStyle = {
  minHeight: '40px',
  borderRadius: '4px',
  paddingTop:'6px',
  paddingBottom:'6px',
  paddingLeft:'16px',
  paddingRight:'16px'
}
const textStyle = {
  border: '1',
  borderColor: 'grey',
  borderStyle: 'solid',
  padding: '1.5rem',
  overflow: 'auto',
  height: '50vh',
}
const formStyle = {
  padding: '1rem',
}
const rowStyle = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center' as any ,
  width: '100%',
} 
const marginStyle = {
  margin: '2rem',
}
const itemStyle = {
  margin: '0.5rem',
}
const logoStyle = {
  display: 'flex',
  marginBottom: '1rem',
  height: '3rem',
  justifyContent: 'center',
  width: '100%',
}
const scrollHeaderStyle = {
  fontSize: '16pt',
  fontWeight: 800,
  color: 'red',
}

export interface AccountMenuProperties {
  host?: string
  tokenFactory?: () => Promise<string>
  children: React.ReactNode
}

export function Eula({ host, tokenFactory, children }: AccountMenuProperties) {
  const [disabled, setDisabled] = useState<boolean>(true)
  const [accept, setAccept] = useState<boolean>(false)
  const [declined, setDeclined] = useState<boolean>(false)
  const [completed, setCompleted] = useState<boolean>(false)
  const [display, setDisplay] = useState<boolean>(false)
  const [eulaContent, setEulaContent] = useState<string>()
  const [eulaId, setEulaId] = useState<string>()
  const { getEulasNotSigned, getEulaContent, signEula, eulas, error } =
    useEulaService({
      host,
      tokenFactory,
    })

  useEffect(() => {
    getEulasNotSigned()
  }, [host, tokenFactory])

  useEffect(() => {
    const getContent = async () => {
      if (eulas) {
        if (eulas.length > 0) {
          setDisplay(true)
          //get first eula
          for (var i = 0; i < 1; i++) {
            var content = await getEulaContent(eulas[i])
            setEulaId(eulas[i])
            setEulaContent(content)
          }
        } else {
          setCompleted(true)
        }
      }
    }
    getContent()
  }, [eulas])

  const contentBuilder = () => {
    if (error) {
      return {
        __html: `<h2>${error}</h2>`,
      }
    }
    return {
      __html: eulaContent || '',
    }
  }

  const handleAcceptEula = (event: React.ChangeEvent<HTMLInputElement>) => {
    var val = event.target.value
    var accepted = val === 'accept'
    setAccept(accepted)
  }

  const handleSignEula = async (
    event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    event.preventDefault()
    if (accept) {
      setEulaContent(undefined) // new to clear
      const elmnt = document.getElementById('divRef')
      setAccept(false)
      setDisabled(true)
      await signEula(eulaId || '0')
      if (elmnt) {
        elmnt.scrollTop = 0
      }
    } else {
      //they declined
      setDeclined(true)
    }
  }

  const handleContainerOnBottom = () => {
    setDisabled(false)
  }

  const renderEulaForm = () => {
    // no content we return null
    if (!eulaContent) {
      return null
    }
    return (
      <div style={containerStyle}>
        <Logo logoStyle={logoStyle} />
        <div style={scrollHeaderStyle}>
          You must read and scroll to the end of the EULA for 'submit' to become
          active.
        </div>
        <BottomScrollListener
          onBottom={handleContainerOnBottom}
          triggerOnNoScroll={true}
        >
          {(scrollRef) => (
            <div
              id={'divRef'}
              ref={scrollRef as React.RefObject<HTMLDivElement>}
              style={textStyle}
              dangerouslySetInnerHTML={contentBuilder()}
            />
          )}
        </BottomScrollListener>

        <form style={formStyle}>
          <div style={rowStyle}>
            <div style={marginStyle}>
              <input
                onChange={handleAcceptEula}
                style={itemStyle}
                type="radio"
                id="accept"
                name="accept"
                checked={accept}
                value="accept"
              />
              <label htmlFor="accept">ACCEPT</label>
            </div>
            <div style={marginStyle}>
              <input
                onChange={handleAcceptEula}
                style={itemStyle}
                type="radio"
                id="decline"
                name="accept"
                checked={!accept}
                value="decline"
              />
              <label htmlFor="decline">DECLINE</label>
            </div>
          </div>
          <div style={rowStyle}>
            <button style={buttonStyle} disabled={disabled} onClick={handleSignEula}>
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    )
  }

  return completed ? (
    <>{children}</>
  ) : declined ? (
    <NotAuthorized />
  ) : display || error ? (
    renderEulaForm()
  ) : null
}

import * as React from 'react'

export interface LogoProperties {
  className?: string
  onClick?: (e: React.MouseEvent) => void
  logoStyle:{}
}

const Logo: React.FunctionComponent<LogoProperties> = ({ className, onClick,logoStyle }) => {
  return (
    <svg
      style={logoStyle}
      viewBox='400 395 3970 1670'
      preserveAspectRatio='xMidYMid meet'
      className={className}
      onClick={onClick}
      focusable='false'
    >
      <g>
        <polygon points='1870,819 4350,819 4350,405 1870,405 ' />
        <polygon points='413,819 728,819 728,405 413,405 ' />
        <polygon points='905,819 1693,819 1693,405 905,405 ' />
        <path d='M2552 1437c0,-97 -33,-176 -96,-226 -64,-51 -139,-63 -250,-63l-337 0 0 898 183 0 0 -736 161 0c45,0 78,6 100,19 32,19 51,54 51,109 0,94 -58,127 -133,127l-122 0 0 163 73 0 190 319 211 0 -211 -350c123,-36 180,-138 180,-259z' />
        <path d='M3106 1537c-130,-44 -275,-50 -275,-148 0,-63 58,-93 140,-93 80,0 152,7 273,40l0 -163c-104,-29 -180,-40 -278,-40 -178,0 -328,71 -328,251 0,143 71,214 211,263 125,43 266,51 266,147 0,72 -70,102 -171,102 -98,0 -160,-8 -286,-42l0 163c105,31 193,40 295,40 223,0 354,-97 354,-278 0,-143 -94,-207 -201,-244z' />
        <polygon points='4112,1148 3791,2046 3965,2046 4169,1475 4172,1475 4172,2046 4350,2046 4350,1148 ' />
        <polygon points='3853,1707 3645,1148 3403,1148 3403,2046 3579,2046 3579,1475 3582,1475 3763,1959 ' />
      </g>
    </svg>
  )
}

export { Logo }
